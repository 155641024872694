var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "essential-information-box" }, [
    _c(
      "div",
      { staticClass: "information-box" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                [
                  _c(
                    "el-image",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: _vm.imgUrl,
                        "preview-src-list": _vm.imgSrcList,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("div", { staticClass: "default-error-img" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/img/car-pic.png"),
                                alt: "",
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c(
                "div",
                { staticClass: "information-box-right" },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _vm._v(" 车辆归属信息 "),
                  ]),
                  _c(
                    "el-form",
                    {
                      staticClass: "ascription-form",
                      attrs: { inline: true, model: _vm.ascriptionForm },
                    },
                    _vm._l(_vm.ascriptionFormLabel, function (item, i) {
                      return _c(
                        "el-form-item",
                        { key: i, attrs: { label: item.label } },
                        [
                          item.key == "authStatus"
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("authStatusFilter")(
                                      _vm.ascriptionForm[item.key]
                                    )
                                  )
                                ),
                              ])
                            : item.key == "plateColor"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.ascriptionForm[item.key])),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.ascriptionForm[item.key])),
                              ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _c("h2", { staticClass: "title btn-parent" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _vm._v(" 车辆外观信息 "),
                    _c(
                      "div",
                      { staticClass: "btn-box" },
                      [
                        !_vm.editStatus
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.handleEdit },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.edit")))]
                            )
                          : _vm._e(),
                        _vm.editStatus
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: { click: _vm.handleSave },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                            )
                          : _vm._e(),
                        _vm.editStatus
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "small" },
                                on: { click: _vm.handleCancel },
                              },
                              [_vm._v("取消")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      staticClass: "ascription-form",
                      attrs: {
                        inline: true,
                        model: _vm.ascriptionForm,
                        disabled: !_vm.editStatus,
                      },
                    },
                    _vm._l(_vm.appearanceFormLabel, function (item, i) {
                      return _c(
                        "el-form-item",
                        { key: i, attrs: { label: item.label } },
                        [
                          item.type == "input"
                            ? _c("el-input", {
                                attrs: { placeholder: "请输入" + item.label },
                                model: {
                                  value: _vm.ascriptionForm[item.key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ascriptionForm, item.key, $$v)
                                  },
                                  expression: "ascriptionForm[item.key]",
                                },
                              })
                            : _vm._e(),
                          item.type == "select"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" + item.label },
                                  model: {
                                    value: _vm.ascriptionForm[item.key],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ascriptionForm,
                                        item.key,
                                        $$v
                                      )
                                    },
                                    expression: "ascriptionForm[item.key]",
                                  },
                                },
                                _vm._l(
                                  item.selectType == "vehicle"
                                    ? _vm.carTypeList
                                    : _vm.plateTypeList,
                                  function (item, i) {
                                    return _c("el-option", {
                                      key: i,
                                      attrs: {
                                        label: item.desc,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "record-box" }, [
      _c(
        "div",
        { staticClass: "vehicle-binding-box" },
        [
          _vm._m(0),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.vehicleBindingData },
            },
            _vm._l(_vm.vehicleBindingCols, function (item) {
              return _c("el-table-column", {
                key: item.prop,
                attrs: {
                  align: "center",
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  formatter: item.formatter,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vehicle-update-box" },
        [
          _vm._m(1),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.vehicleUpdateData },
            },
            _vm._l(_vm.vehicleUpdateCols, function (item) {
              return _c("el-table-column", {
                key: item.prop,
                attrs: {
                  align: "center",
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  formatter: item.formatter,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 车辆绑定记录 "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 车辆信息修改记录 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }